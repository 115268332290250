import React from 'react'

const Header = () => {
  return (
    <section id="header" className="section pb-4 pb-md-5">
        <div className="container">
           <div className="row">
              <div className="col-md-8">
                <h1 style={{ fontSize: '3rem'}}>Web Developer</h1>
                <p>Hey I'm Azmol from Manchester, United Kingdom. <span className="badge rounded-pill bg-success">Available for Work!</span></p>
                <p>
                   With over four years of experience in building robust software solutions. Proficient in HTML, CSS, JavaScript, React, Laravel, PHP, Python, and SQL in full stack development and data-driven project builds.
                </p>
                <div className="d-flex align-items-center">
                  <button type="button" className="readmore me-3" data-bs-toggle="modal" data-bs-target="#aboutModal">My Experience</button>
                  <a href="mailto:azmol.miah.general@gmail.com" className='readmore'>Email Me</a>
                </div>
              </div>
              {/* <div className="col-4 d-md-none text-center" style={{ padding: '40px 40px 70px 40px', background: '#eceff2'}}>
                <p>Hey 👋 for my freelance website and services visit...</p>
                <a href="https://azmolmiah.co.uk"><h1 className='mb-0'><span className="badge bg-success rounded-0">azmolmiah.co.uk</span></h1></a>
                <p className='mb-0 mt-3'>Or email me <br/> 📧 azmol.miah.general@gmail.com</p>
              </div> */}
           </div>
        </div>
        {/* Modal */}
        <div className="modal fade" id="aboutModal" tabindex="-1" aria-labelledby="aboutModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
            <div className="modal-content rounded-0">
              <div className="modal-header border-0">
                <h1 className="modal-title" id="aboutModalLabel">About Me</h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <p>I've been practicing web development for more than 6 years, both within a company and for freelance clients. I'm currently freelancing and recently completed a Data engineering course. Let me tell you about some of the solutions I used in my previous jobs.</p>
                <p className='fw-bold'>PHP developer at Navari Group from Sept 2023 to Nov 2024: </p>
                <ul>
                  <li className='mb-3'>Creating fullstack Laravel application with components such as messaging, broadcastng, email notifications, etc. This application was to optimise and digitize the management of care infrastructure or property.</li>
                  <li className='mb-3'>Refactoring frontend code with modern UI libraries like bootstrap within a few weeks because the outsourcing company did not meet the requirements or deadlines. So I saved them money and time whenever they needed anything frontend.</li>
                  <li className='mb-3'>Refactored legacy Laravel code because the senior developer wasnt happy with the structure or quality from previous developer</li>
                  <li>My daily process included checking the requirements on jira, testing code before commiting, and then pushing the branch to repo for staging. All whilst aiming for a 48 hour SLA on each task.</li>
                </ul>
                <p className='fw-bold'>Short Course Provision Developer at Codenation from Jun 2021 to Sept 2022: </p>
                <ul>
                  <li className='mb-3'>During the Covid-19 pandemic, I began instructing a remote Cybersecurity course that lacked an LMS, unlike my previous experience with EC-Council University. To manage student submissions effectively, I implemented Google Classroom. Additionally, I used JavaScript and Google Apps Script to automate the tracking of submissions, organizing files in Google Drive, and sending email reminders, which streamlined Google Classrooms.</li>
                  <li className='mb-3'>We were also using platforms to teach vulnerabilities. So my task was to come up with a mock e commerce web app that was vulnerable to different security issues. I learned both the basics of the LAMP stack and known vulnerabilties that used to come with these technologies and implemented a app that allowed students to both practice and track their progress using a CTF feature.</li>
                  <li>Since I had some experience with building apps I was able to teach both Cyber and Coding cohorts</li>
                </ul>
              </div>
              <div className="modal-footer">
                <a href="mailto:azmol.miah.general@gmail.com" className='readmore'>Email Me</a>
                <button type="button" className="rounded-0 readmore" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
    </section>
  )
}

export default Header