import React from 'react';

const Contact = () => {
  return (
    <section id="contact" className="section py-5">
      <div className="container">
        <div className="row mb-5 align-items-end">
          <div className="" data-aos="fade-up">
            <h2>Contact</h2>
            <p className="mb-0">Looking to streamline your processes, enhance your web presence, or optimize your data workflows?
              Let's join forces to accelerate 🚀 your business in automation, web development, APIs, and data engineering.
            </p>
          </div>
        </div>

        <div className="row">
          <div className=" mb-5 mb-md-0" data-aos="fade-up">
            <form name="portfolio-contact" method="POST" data-netlify="true" action="/">
              <input type="hidden" name="form-name" value="portfolio-contact" />
              <div className="row gy-3">
                <div className="col-md-6 form-group">
                  <label htmlFor="name">Name</label>
                  <input type="text" name="name" className="form-control" id="name" required />
                </div>
                <div className="col-md-6 form-group">
                  <label htmlFor="email">Email</label>
                  <input type="email" className="form-control" name="email" id="email" required />
                </div>
                <div className="col-md-12 form-group">
                  <label htmlFor="subject">Subject</label>
                  <input type="text" className="form-control" name="subject" id="subject" required />
                </div>
                <div className="col-md-12 form-group">
                  <label htmlFor="message">Message</label>
                  <textarea className="form-control" name="message" id="message" cols="30" rows="10" required></textarea>
                </div>
                <div className="col-md-6 offset-md-3 mt-5 form-group">
                  <button type="submit" className="readmore d-block w-100">Send Message</button>
                </div>
              </div>
            </form>
          </div>
          {/* <div className="col-md-4 ml-auto order-2" data-aos="fade-up">
            <ul className="list-unstyled">
              <li className="mb-3">
                <strong className="d-block mb-1">Address</strong>
                <span>203 Fake St. Mountain View, San Francisco, California, USA</span>
              </li>
              <li className="mb-3">
                <strong className="d-block mb-1">Phone</strong>
                <span>+1 232 3235 324</span>
              </li>
              <li className="mb-3">
                <strong className="d-block mb-1">Email</strong>
                <span>youremail@domain.com</span>
              </li>
            </ul>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default Contact;
