import React, { useEffect } from 'react';
import Isotope from 'isotope-layout';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Projects = ({ coding_projects }) => {
  useEffect(() => {
    // Initialize AOS
    AOS.init({
      duration: 1000,
      once: true,
    });

    // Ensure images are loaded before initializing Isotope
    const handleLoad = () => {
      const portfolioContainer = document.querySelector('#portfolio-grid');
      if (portfolioContainer) {
        const portfolioIsotope = new Isotope(portfolioContainer, {
          itemSelector: '.item',
        });

        const portfolioFilters = document.querySelectorAll('#filters button');

        portfolioFilters.forEach(filter => {
          filter.addEventListener('click', function (e) {
            e.preventDefault();
            portfolioFilters.forEach(el => el.classList.remove('active'));
            this.classList.add('active');

            portfolioIsotope.arrange({
              filter: this.getAttribute('data-filter'),
            });
            portfolioIsotope.on('arrangeComplete', function () {
              AOS.refresh();
            });
          });
        });
      }
    };

    const images = document.querySelectorAll('#portfolio-grid img');
    let imagesLoaded = 0;
    images.forEach((img) => {
      if (img.complete) {
        imagesLoaded++;
      } else {
        img.onload = () => {
          imagesLoaded++;
          if (imagesLoaded === images.length) {
            handleLoad();
          }
        };
      }
    });

    if (imagesLoaded === images.length) {
      handleLoad();
    }

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, []);

  return (
    <section id="works" className="section site-portfolio">
      <div className="container">
        <div className="row mb-md-5 align-items-center">
          <div className="col-md-12 col-lg-6 mb-4 mb-lg-0" data-aos="fade-up">
            <h2>Projects</h2>
            <p className="mb-0">💻 Software engineering, DevOps, Cloud Engineering, Cyber Security, Data Engineering</p>
          </div>
          <div className="col-md-12 col-lg-6 text-start text-lg-end" data-aos="fade-up" data-aos-delay="100">
            <div id="filters" className="filters mb-4 mb-md-0">
              <button data-filter="*" className="active">All</button>
              <button data-filter=".data">Data</button>
              <button data-filter=".coding">Coding</button>
              <button data-filter=".automation">Automation</button>
              <button data-filter=".security">Security</button>
            </div>
          </div>
        </div>
        <div id="portfolio-grid" className="row no-gutter" data-aos="fade-up" data-aos-delay="200">
          {coding_projects && coding_projects.map((project, index) => (
            <div key={index} className={`item ${project.category} col-sm-6 col-md-4 col-lg-4 mb-4`} >
     
                <div className="item-wrap fancybox mb-3">
                  <div className="work-info">
                    <h3>{project.title}</h3>
                    <span>{project.description}</span>
                    <div className="d-flex justify-content-center align-items-center mt-2">
                      <a href={project.link}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#fff" class="bi bi-arrow-up-right-square" viewBox="0 0 16 16">
                          <path fill-rule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm5.854 8.803a.5.5 0 1 1-.708-.707L9.243 6H6.475a.5.5 0 1 1 0-1h3.975a.5.5 0 0 1 .5.5v3.975a.5.5 0 1 1-1 0V6.707z"/>
                        </svg>
                      </a>
                      { project.github &&
                        <a href={project.github} className='ms-2'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#fff" className="bi bi-github" viewBox="0 0 16 16">
                            <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27s1.36.09 2 .27c1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.01 8.01 0 0 0 16 8c0-4.42-3.58-8-8-8"/>
                          </svg>
                        </a>
                      }
                    </div>
                  </div>
                  
                    <img className="img-fluid" src={project.imageUrl} alt={project.title}/>
                </div>

                {/* <div className="d-flex justify-content-end align-items-center">
                <a href="#">
                  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-arrow-up-right-square" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm5.854 8.803a.5.5 0 1 1-.708-.707L9.243 6H6.475a.5.5 0 1 1 0-1h3.975a.5.5 0 0 1 .5.5v3.975a.5.5 0 1 1-1 0V6.707z"/>
                  </svg>
                </a>
                { project.github &&
                  <a href={project.github} className='ms-2'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-github" viewBox="0 0 16 16">
                      <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27s1.36.09 2 .27c1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.01 8.01 0 0 0 16 8c0-4.42-3.58-8-8-8"/>
                    </svg>
                  </a>
                }
                </div> */}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Projects;
