import React from 'react';

const Footer = () => {
  return (
    <footer className="footer pt-5 bg-dark" role="contentinfo">
      <div className="container">
        <div className="row">
          <div className="col-sm-6">
            <p className="mb-1 text-white">&copy; Copyright Azmol Miah. All Rights Reserved</p>
          </div>
          <div className="col-sm-6 social text-md-end">
            <a href="https://www.linkedin.com/in/azmolmiah/" target="_blank" rel="noopener noreferrer"><span className="bi bi-linkedin"></span></a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;