import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHtml5, faCss3Alt, faJs, faReact, faPython, faLaravel, faPhp, faGitAlt, faWordpress, faShopify, faStripe, faAws } from '@fortawesome/free-brands-svg-icons';

const Skills = () => {
  return (
    <section className="section">
      <div className="container">
        <div className="row justify-content-center text-center mb-4">
          <div className="col-5">
            <h3 className="h3 heading">My Skills</h3>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit explicabo inventore.</p>
          </div>
        </div>
        <div className="row g-4">
          <div className="col-4 col-sm-4 col-md-2 offset-1">
            <div className="skill">
              <FontAwesomeIcon icon={faHtml5} size="4x" />
              {/* <p>HTML</p> */}
            </div>
          </div>
          <div className="col-4 col-sm-4 col-md-2 offset-1">
            <div className="skill">
              <FontAwesomeIcon icon={faCss3Alt} size="4x" />
              {/* <p>CSS</p> */}
            </div>
          </div>
          <div className="col-4 col-sm-4 col-md-2 offset-1">
            <div className="skill">
              <FontAwesomeIcon icon={faJs} size="4x" />
              {/* <p>JavaScript</p> */}
            </div>
          </div>
          <div className="col-4 col-sm-4 col-md-2 offset-1">
            <div className="skill">
              <FontAwesomeIcon icon={faReact} size="4x" />
              {/* <p>React</p> */}
            </div>
          </div>
          <div className="col-4 col-sm-4 col-md-2 offset-1">
            <div className="skill">
              <FontAwesomeIcon icon={faPhp} size="4x" />
              {/* <p>PHP</p> */}
            </div>
          </div>
          <div className="col-4 col-sm-4 col-md-2 offset-1">
            <div className="skill">
              <FontAwesomeIcon icon={faWordpress} size="4x" />
              {/* <p>Laravel</p> */}
            </div>
          </div>
          <div className="col-4 col-sm-4 col-md-2 offset-1">
            <div className="skill">
              <FontAwesomeIcon icon={faLaravel} size="4x" />
              {/* <p>Laravel</p> */}
            </div>
          </div>
          <div className="col-4 col-sm-4 col-md-2 offset-1">
            <div className="skill">
              <FontAwesomeIcon icon={faPython} size="4x" />
              {/* <p>Python</p> */}
            </div>
          </div>
          <div className="col-4 col-sm-4 col-md-2 offset-1">
            <div className="skill">
              <FontAwesomeIcon icon={faGitAlt} size="4x" />
              {/* <p>Git</p> */}
            </div>
          </div>
          <div className="col-4 col-sm-4 col-md-2 offset-1">
            <div className="skill">
              <FontAwesomeIcon icon={faShopify} size="4x" />
              {/* <p>Git</p> */}
            </div>
          </div>
          <div className="col-4 col-sm-4 col-md-2 offset-1">
            <div className="skill">
              <FontAwesomeIcon icon={faStripe} size="4x" />
              {/* <p>Git</p> */}
            </div>
          </div>
          <div className="col-4 col-sm-4 col-md-2 offset-1">
            <div className="skill">
              <FontAwesomeIcon icon={faAws} size="4x" />
              {/* <p>Git</p> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Skills;
