import React, { useState, useEffect } from 'react';
import { Header, Projects, Skills, Services, Testimonials, Contact, Footer } from './sections';
import Nav from './components/Nav';
import { createClient } from 'contentful';

const client = createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
});

function App() {
  const [fixedNavData, setFixedNavData] = useState(null);
  const [featureProjectsData, setFeatureProjectsData] = useState(null);
  const [testimonialsData, setTestimonialsData] = useState(null);

  useEffect(() => {
    client.getEntries({
      content_type: 'fixedNav',
      limit: 10,
      skip: 0,
    })
      .then(response => {
        if (response.items.length > 0) {
          setFixedNavData(response.items[0]);
        }
      })
      .catch(console.error);

    client.getEntries({
      content_type: 'featureProjects',
      limit: 10,
      skip: 0,
    })
      .then(response => {
        console.log(response)
        const transformedData = response.items.map(item => ({
          title: item.fields.title,
          description: item.fields.excerpt,
          imageUrl: item.fields.image.fields.file.url,
          github: item.fields.githubLink,
          link: item.fields.liveLink,
          category: item.fields.category,
          tags: item.fields.tags
        }));
        setFeatureProjectsData(transformedData);
      })
      .catch(console.error);

      client.getEntries({
        content_type: 'testimonials',
        limit: 10,
        skip: 0,
      })
        .then(response => {
          if (response.items.length > 0) {
            const transformedData = response.items.map(item => ({
              message: item.fields.message,
              name: item.fields.name,
              role: item.fields.role,
              organisation: item.fields.organisation,
              website: item.fields.website,
              linkedin: item.fields.linkedin,
            }));
            setTestimonialsData(transformedData);
          }
        })
        .catch(console.error);
  }, []);

  if (!featureProjectsData) {
    return <p>Loading projects...</p>;
  }

  console.log(featureProjectsData)

  return (
    <div className="App">
      <Nav data={fixedNavData} />
      <div id="main">        
        <Header/>
        <Projects coding_projects={featureProjectsData} />
        <Skills />
        <Services />
        <Testimonials testimonials={testimonialsData} />
        <Contact />
        <Footer />
      </div>
    </div>
  );
}

export default App;
